<template>
    <div>
        <el-form :model="form" ref="form" label-width="180px" class="demo-form" :rules="rules">
          <el-form-item :label="$t('AppManage.AppSecurityEvent.EventName')+'：'" prop="name">
            <el-input size="small" v-model="form.name" maxlength="20" style="width: 540px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('AppManage.AppSecurityEvent.EventDescription')+'：'">
            <el-input size="mini" v-model="form.memo" maxlength="200" style="width: 540px; height: 100px; resize: none"
              type="textarea" :rows="5" :cols="200" id="text"></el-input>
          </el-form-item>
          <el-form-item :label="$t('AppManage.AppSecurityEvent.Condition')+'：'" prop="env">
            <div style="
                width: 540px;
                height: 165px;
                border: 1px solid #ccc;
                border-radius: 5px;
              ">
              <div style="
                  width: 520px;
                  height: 120px;
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  margin: 5px 0 0 9px;
                ">
                <el-checkbox label="S_CHECK_ROOT_STATUS" v-model="form.content.env.root.switch" value="0" true-label="1"
                  false-label="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.ROOTJailbreak')}}</el-checkbox>
                <el-checkbox label="S_ADB_ENABLED" v-model="form.content.env.usb.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.USBDebugging')}}</el-checkbox>
                <el-checkbox label="S_CHECK_VM_STATUS" v-model="form.content.env.vm.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.Simulator')}}</el-checkbox>
                <el-checkbox label="S_ALLOW_MOCK_LOCATION" v-model="form.content.env.location.switch" true-label="1"
                  false-label="0" value="0" style="width: 20%">{{$t('AppManage.AppSecurityEvent.Simulatedposition')}}</el-checkbox>
                <el-checkbox label="S_CHECK_CUSTOM_ROM" v-model="form.content.env.rom.switch" value="0" true-label="1"
                  false-label="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.CustomROM')}}</el-checkbox>
                  <el-checkbox style="width: 22%" v-model="form.content.env.S_FALSE_DEVICE.switch" value="0" true-label="1" false-label="0">
                    {{$t('AppManage.AppSecurityEvent.Equipmentforgery')}}</el-checkbox>
                <el-checkbox v-model="form.content.env.S_CHECK_SYSTEM_TIME.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.Systemtimeanomaly')}}</el-checkbox>
                <el-checkbox v-model="form.content.env.S_CHECK_SCREEN_PASSWORD.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">{{$t('AppManage.AppSecurityEvent.Locknotenabled')}}</el-checkbox>
                  <el-checkbox v-model="form.content.env.S_MACOS_STATUS.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">macOS</el-checkbox>
                  <el-checkbox v-model="form.content.env.S_WINDOWS_STATUS.switch" true-label="1" false-label="0"
                  value="0" style="width: 22%">Windows</el-checkbox>
                  <el-checkbox v-model="form.content.env.S_VPN_STATUS.switch" true-label="1" false-label="0"
                  value="0" style="width: 20%">VPN</el-checkbox>
                  <el-checkbox v-model="form.content.env.S_CLOUD_PHONE_STATUS.switch" true-label="1" false-label="0"
                  value="0" style="width: 21%;margin-left:14px;">{{$t('AppManage.AppSecurityEvent.CloudPhone')}}</el-checkbox>
              </div>
              <div>
                <el-radio v-model="form.content.env.mode" label="0" value="0" true-label="1" false-label="0">{{$t('AppManage.AppSecurityEvent.Satisfyany')}}
                </el-radio>
                <el-radio v-model="form.content.env.mode" label="1" value="1" true-label="1" false-label="0">{{$t('AppManage.AppSecurityEvent.Satisfyall')}}
                </el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('AppManage.AppSecurityEvent.Threatdimension')+'：'" prop="threat" style="margin-bottom: 0px">
            <div style="
                width: 540px;
                height: 340px;
                border: 1px solid #ccc;
                border-radius: 5px;
              ">
              <div style="width: 100%">
                <el-checkbox :label="$t('AppManage.AppSecurityEvent.NumberThreats')" v-model="form.content.threat.count.switch" true-label="1" false-label="0"
                  @change="isshow"></el-checkbox>
                <el-select v-model="form.content.threat.count.type" slot="prepend" :placeholder="$t('AppManage.AppSecurityEvent.Pleaseselect')" size="mini"
                  style="margin-left: 80px; width: 120px" :disabled="!form.content.threat.count.switch">
                  <el-option :label="$t('AppManage.AppSecurityEvent.Injectionattack')" value="S_CHECK_INJECT_STATUS"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Frameworkattacks')"  value="S_CHECK_FRAME_ATTACK"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Debuggingbehavior')"  value="S_CHECK_DEBUG_STATUS"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Locationfraud')" value="S_CHECK_LOCATION_FRAUD"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Domainnamefraud')" value="S_CHECK_DOMAIN"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Programplugin')" value="S_CHECK_PLUGIN"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Applicationcrack')" value="S_CHECK_SIGNATURE"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.HTTPShijacking')" value="S_CHECK_ABNORMAL_CA"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Httpproxy')" value="S_CHECK_WIFI_PROXY"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.AppMultipleOpen')"  value="S_CHECK_MULTI_APK"></el-option>
                  <!-- <el-option
                    label="高频更换账号"
                    value="S_CHECK_FREQUENCY_ACCOUNT"
                  ></el-option>
                  <el-option label="高频更换IP" value="S_CHECK_FREQUENCY_IP"></el-option>
                  <el-option
                    label="高频更换位置"
                    value="S_CHECK_FREQUENCY_LOCATION"
                  ></el-option>
                  <el-option
                    label="高频启动应用"
                    value="S_CHECK_FREQUENCY_RESTART"
                  ></el-option> -->
                  <el-option :label="$t('AppManage.AppSecurityEvent.Framesoftware')" value="S_FRAME_ATTACH"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Riskapplication')" value="S_CHECK_DANGER_APPS"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Rootcertificate')" value="S_CHECK_SYS_USER_CA"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.Memorytampering')" value="S_CHECK_MEMORY_CHANGE"></el-option>
                  <el-option :label="$t('AppManage.AppSecurityEvent.RiskIP')" value="S_CHECK_DANGER_IP"></el-option>
                </el-select>
                <span style="margin-left: 20px">{{$t('AppManage.AppSecurityEvent.Occur')}}&ensp;
                  <el-input v-model="form.content.threat.count.count" label-width="80px" style="width: 80px; height: 24px"
                    size="mini" :disabled="!form.content.threat.count.switch"></el-input>
                  &ensp;{{$t('AppManage.AppSecurityEvent.Order')}}
                </span>
              </div>
              <el-form-item style="width: 100%" prop="count">
                <el-checkbox :label="$t('AppManage.AppSecurityEvent.Threattypes')" v-model="form.content.threat.category.switch" true-label="1" false-label="0"
                  @change="isshow1"></el-checkbox>
                <span style="margin-left: 83px">{{$t('AppManage.AppSecurityEvent.Threatoccurrence')}}&emsp;&emsp;
  
                  <el-input v-model="form.content.threat.category.count" :maxlength="2" label-width="80px"
                    style="width: 80px; height: 24px" size="mini" :disabled="!form.content.threat.category.switch"
                    onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
  
                  &emsp;&emsp;{{$t('AppManage.AppSecurityEvent.Species')}}
                </span>
              </el-form-item>
              <template>
                <el-checkbox :label="$t('AppManage.AppSecurityEvent.Containsthreats')" v-model="form.content.threat.type.switch" true-label="1" false-label="0"
                  @change="isshow2"></el-checkbox>
              </template>
              <div style="
                  width: 520px;
                  height: 165px;
                  border: 1px solid #ccc;
                  border-radius: 5px;
                  margin: 5px 0 0 9px;
                ">
                <el-checkbox-group v-model="form.content.threat.type.type">
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%"
                    label="S_CHECK_INJECT_STATUS" value="0">{{ $t('AppManage.AppSecurityEvent.Injectionattack') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%"
                    label="S_CHECK_FRAME_ATTACK" value="1">{{ $t('AppManage.AppSecurityEvent.Frameworkattacks') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%"
                    label="S_CHECK_DEBUG_STATUS" value="2">{{ $t('AppManage.AppSecurityEvent.Debuggingbehavior') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%"
                    label="S_CHECK_LOCATION_FRAUD" value="3">{{ $t('AppManage.AppSecurityEvent.Locationfraud') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_DOMAIN"
                    value="4">{{$t('AppManage.AppSecurityEvent.Domainnamefraud')}}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_PLUGIN"
                    value="5">{{ $t('AppManage.AppSecurityEvent.Programplugin') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_SIGNATURE"
                    value="6">{{ $t('AppManage.AppSecurityEvent.Applicationcrack') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_ABNORMAL_CA"
                    value="7">{{ $t('AppManage.AppSecurityEvent.HTTPShijacking') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_WIFI_PROXY"
                    value="8">{{ $t('AppManage.AppSecurityEvent.Httpproxy') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_MULTI_APK"
                    value="9">{{ $t('AppManage.AppSecurityEvent.AppMultipleOpen') }}</el-checkbox>
                  <!-- <el-checkbox
                    :disabled="!form.content.threat.type.switch"
                    style="width: 22%"
                    label="S_CHECK_FREQUENCY_ACCOUNT"
                    value="10"
                    >高频更换账号</el-checkbox
                  >
                  <el-checkbox
                    :disabled="!form.content.threat.type.switch"
                    style="width: 22%"
                    label="S_CHECK_FREQUENCY_IP"
                    value="11"
                    >高频更换IP</el-checkbox
                  >
                  <el-checkbox
                    :disabled="!form.content.threat.type.switch"
                    style="width: 22%"
                    label="S_CHECK_FREQUENCY_LOCATION"
                    value="12"
                    >高频更换位置</el-checkbox
                  >
                  <el-checkbox
                    :disabled="!form.content.threat.type.switch"
                    style="width: 22%"
                    label="S_CHECK_FREQUENCY_RESTART"
                    value="13"
                    >高频启动应用</el-checkbox
                  > -->
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_FRAME_ATTACH"
                    value="14">{{ $t('AppManage.AppSecurityEvent.Framesoftware') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%" label="S_CHECK_DANGER_APPS"
                    value="15">{{ $t('AppManage.AppSecurityEvent.Riskapplication') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%; margin-left: 3px"
                    label="S_CHECK_SYS_USER_CA" value="20">{{ $t('AppManage.AppSecurityEvent.Rootcertificate') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%; margin-left: 3px"
                    label="S_CHECK_MEMORY_CHANGE" value="20">{{ $t('AppManage.AppSecurityEvent.Memorytampering') }}</el-checkbox>
                  <el-checkbox :disabled="!form.content.threat.type.switch" style="width: 22%; margin-left: 3px"
                    label="S_CHECK_DANGER_IP" value="20">{{ $t('AppManage.AppSecurityEvent.RiskIP') }}</el-checkbox>
                </el-checkbox-group>
              </div>
              <div>
                <el-radio v-model="form.content.threat.mode" label="0" value="1">{{$t('AppManage.AppSecurityEvent.Satisfyany')}}</el-radio>
                <el-radio v-model="form.content.threat.mode" label="1" value="2">{{$t('AppManage.AppSecurityEvent.Satisfyall')}}</el-radio>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('public.Behaviorlevel')+'：'" prop="level" style="margin-bottom: 0px">
            <el-radio v-model="form.level" label="3" value="1">{{$t('AppManage.AppSecurityEvent.Tall')}}</el-radio>
            <el-radio v-model="form.level" label="2" value="2">{{$t('AppManage.AppSecurityEvent.Centre')}}</el-radio>
            <el-radio v-model="form.level" label="1" value="3">{{$t('AppManage.AppSecurityEvent.Low')}}</el-radio>
          </el-form-item>
          <el-form-item :label="$t('AppManage.AppSecurityEvent.ResponseAction')+'：'" style="margin-bottom: 0px">
            <el-checkbox-group v-model="checkList" @selection-change="handleSelectionChangeScript">
              <el-radio label="0" v-model="form.content.action" value="0">{{$t('AppManage.AppSecurityEvent.NaN')}}</el-radio>
              <el-radio label="4" v-model="form.content.action" value="4">{{$t('AppManage.AppSecurityEvent.AlarmMessage')}}</el-radio>
              <el-radio label="8" v-model="form.content.action" value="8">{{$t('AppManage.AppSecurityEvent.ClearData')}}</el-radio>
              <el-radio label="2" v-model="form.content.action" value="2">{{$t('AppManage.AppSecurityEvent.ExitApplication')}}</el-radio>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('AppManage.AppSecurityEvent.Prohibitionmethod')+'：'" style="margin-bottom: 0px" required>
            <div style="display: flex">
              <el-form-item label-width="0" class="timerules">
                <el-radio v-model="radio" label="1" style="line-height: 40px">{{$t('AppManage.AppSecurityEvent.Continuouslockdown')}}</el-radio>
              </el-form-item>
              <el-form-item label-width="0" prop="content.time" class="timerules">
                <el-input minlength="1" maxlength="4" v-model="form.content.time" style="width: 90px; margin-left: 10px"
                  size="mini" onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label-width="0" class="timerules">
                <span>
                  <el-select v-model="form.content.unit" slot="prepend" :placeholder="$t('AppManage.AppSecurityEvent.Pleaseselect')" size="mini"
                    style="margin-left: 8px; width: 88px">
                    <el-option :label="$t('AppManage.AppSecurityEvent.Hour')" value="hour"></el-option>
                    <el-option :label="$t('AppManage.AppSecurityEvent.Order')" value="count"></el-option>
                  </el-select>
                </span>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="add('form')" size="mini" class="save">{{ $t('public.Save') }}</el-button>
        </div>
      </div>
</template>

<script>
export default {
  data () {
    return {
       // 单选
       radio: "1",
      // 多选
      checkList: [],
      form: {
        name: "", //名称
        memo: "", //描述
        level: "3", //级别，1-低，2-中，3-高
        content: {
          //事件内容
          env: {
            //环境状态
            mode: "0", //0-满足任一勾选条件，1-满足全部勾选条件，以下同
            root: {
              //ROOT/越狱
              switch: "0", //1-勾选，0-未勾选，以下同
            },
            usb: {
              //USB调试
              switch: "0",
            },
            location: {
              //允许模拟位置
              switch: "0",
            },
            rom: {
              //定制ROM
              switch: "0",
            },
            vm: {
              //模拟器
              switch: "0",
            },
            S_FALSE_DEVICE: {
              switch: "0",
            },
            S_CHECK_SYSTEM_TIME: {
              switch: "0",
            },
            S_CHECK_SCREEN_PASSWORD: {
              switch: "0",
            },
            S_WINDOWS_STATUS:{
              switch: "0",
            },
            S_MACOS_STATUS:{
              switch: "0",
            },
            S_VPN_STATUS:{
              switch: "0",
            },
            S_CLOUD_PHONE_STATUS:{
              switch: "0",
            },
          },
          threat: {
            //威胁维度
            mode: "0",
            count: {
              //威胁次数
              switch: "",
              type: "S_CHECK_INJECT_STATUS", //威胁类型, 参考国网总部
              count: "10", //次数
            },
            category: {
              //威胁种类
              switch: "",
              count: "1", //种类数
            },
            type: {
              //包含威胁
              switch: "",
              type: [], //选择威胁种类
            },
          },
          action: "0", //4-告警提示，8-清除数据， 2-退出应用
          time: "1", //封禁数量
          unit: "hour", //封禁单位，hour-小时，count-次数
        },
      },
      rules: {
        name: [
          { required: true, message: "请输入事件名称", trigger: "blur" },
          { min: 1, max: 20, message: "最长输入20个字符", trigger: "blur" },
        ],
        memo: [
          { required: true, message: "请输入事件描述", trigger: "blur" },
          { min: 1, max: 200, message: "最长输入200个字符", trigger: "blur" },
        ],
        content: {
          time: [
            { required: true, message: "请输入封禁方式", trigger: "blur" },
            { min: 1, max: 200, message: "最长输入4个字符", trigger: "blur" },
          ],
        },
      },
    }
  },
  components: {

  },
  mounted() {

  },
  methods: {
    // 威胁次数 禁用
    handleSelectionChangeScript(selection) {
      //多选改单选
      if (selection.length === 0) {
        this.selectioned = null;
      }
    },
    isshow(val) {
      if (val == 0) {
        this.form.content.threat.count.switch = 0;
      }
      // console.log(val);
    },
    isshow3(val) {
      if (val == 0) {
        this.form.content.threat.count.switch = 0;
      }
      // console.log(val);
    },
    isshow1(val) {
      if (val == 0) {
        this.form.content.threat.category.switch = 0;
      }
    },
    isshow2(val) {
      // console.log(val);
      if (val == 0) {
        this.form.content.threat.type.switch = 0;
      }
    },
    // 新增
    add(form) {
      if (
        this.form.content.threat.count.count > parseInt(9999) ||
        this.form.content.threat.count.count < parseInt(1)
      ) {
        this.$message({
          message: this.$t('AppManage.AppSecurityEvent.Numberthreats'),
          type: 'error',
          offset: 100
        })
        return false
      }
      if (
        this.form.content.threat.category.count > parseInt(15) ||
        this.form.content.threat.category.count < parseInt(1)
      ) {
        this.$message({
          message: this.$t('AppManage.AppSecurityEvent.Threatsoccur'),
          type: 'error',
          offset: 100
        })
        return false
      }

      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          this.newAdd(); //新增保存接口方法拆分出来
        } else {
          console.log("error");
          return false;
        }
      });
    },
    //新增保存接口方法拆分出来
    async newAdd() {
      var param = {
        id: this.id,
        name: this.form.name, //名称
        memo: this.form.memo, //描述
        level: this.form.level, //级别，1-低，2-中，3-高
        type: "event", //类别
        os: "All", //操作系统
        content: {
          //事件内容
          env: {
            //环境状态
            mode: this.form.content.env.mode, //0-满足任一勾选条件，1-满足全部勾选条件，以下同
            root: {
              //ROOT/越狱
              switch: this.form.content.env.root.switch, //1-勾选，0-未勾选，以下同
            },
            usb: {
              //USB调试
              switch: this.form.content.env.usb.switch,
            },
            location: {
              //允许模拟位置
              switch: this.form.content.env.location.switch,
            },
            rom: {
              //定制ROM
              switch: this.form.content.env.rom.switch,
            },
            vm: {
              //模拟器
              switch: this.form.content.env.vm.switch,
            },
            S_FALSE_DEVICE: {
              switch: this.form.content.env.S_FALSE_DEVICE.switch,
            },
            S_CHECK_SYSTEM_TIME: {
              switch: this.form.content.env.S_CHECK_SYSTEM_TIME.switch,
            },
            S_CHECK_SCREEN_PASSWORD: {
              switch: this.form.content.env.S_CHECK_SCREEN_PASSWORD.switch,
            },
            S_WINDOWS_STATUS:{
              switch: this.form.content.env.S_WINDOWS_STATUS.switch,
            },
            S_MACOS_STATUS:{
              switch: this.form.content.env.S_MACOS_STATUS.switch,
            },
            S_VPN_STATUS:{
              switch: this.form.content.env.S_VPN_STATUS.switch,
            },
            S_CLOUD_PHONE_STATUS:{
              switch: this.form.content.env.S_CLOUD_PHONE_STATUS.switch,
            },
          },
          threat: {
            //威胁维度
            mode: this.form.content.threat.mode,
            count: {
              //威胁次数
              switch: this.form.content.threat.count.switch,
              type: this.form.content.threat.count.type, //威胁类型, 参考国网总部
              count: this.form.content.threat.count.count, //次数
            },
            category: {
              //威胁种类
              switch: this.form.content.threat.category.switch,
              count: this.form.content.threat.category.count, //种类数
            },
            type: {
              //包含威胁
              switch: this.form.content.threat.type.switch,
              type: this.form.content.threat.type.type, //选择威胁种类
            },
          },
          action: this.form.content.action,
          time: this.form.content.time,
          unit: this.form.content.unit,
        },
      };

      const switchsObj = (switchs, key, isTo) => {
        isTo = isTo || false;
        for (var key2 in switchs) {
          if (switchs[key2] === "1" && key2 === "switch") {
            isTo = true;
            // console.log(switchs[key2], key2, isTo, '1switchs')
            break;
          } else {
            // console.log(switchs[key2], key2, isTo, '2switchs', isTo)
            if (
              switchs[key2] instanceof Object &&
              switchsObj(switchs[key2], key2, isTo)
            ) {
              // switchsObj(switchs[key2], key2, isSwitchTo)会返回true关闭
              // console.log(switchs[key2], key2, isTo, '3switchs')
              isTo = true;
              break;
            }
          }
        }
        return isTo;
      };

      // var switchs = {
      //   env: {
      //     //环境状态
      //     mode: param.content.env.mode, //0-满足任一勾选条件，1-满足全部勾选条件，以下同
      //     root: {
      //       //ROOT/越狱
      //       switch: param.content.env.root.switch //1-勾选，0-未勾选，以下同
      //     },
      //     usb: {
      //       //USB调试
      //       switch: param.content.env.usb.switch
      //     },
      //     location: {
      //       //允许模拟位置
      //       switch: param.content.env.location.switch
      //     },
      //     rom: {
      //       //定制ROM
      //       switch: param.content.env.rom.switch
      //     },
      //     vm: {
      //       //模拟器
      //       switch: param.content.env.vm.switch
      //     }
      //   },
      //   threat: {
      //     //威胁维度
      //     mode: param.content.threat.mode,
      //     count: {
      //       //威胁次数
      //       switch: param.content.threat.count.switch,
      //       type: param.content.threat.count.type, //威胁类型, 参考国网总部
      //       count: param.content.threat.count.count //次数
      //     },
      //     category: {
      //       //威胁种类
      //       switch: param.content.threat.category.switch,
      //       count: param.content.threat.category.count //种类数
      //     },
      //     type: {
      //       //包含威胁
      //       switch: param.content.threat.type.switch,
      //       type: param.content.threat.type.type //选择威胁种类
      //     }
      //   }
      // }
      // console.log(switchsObj(switchs), '外边')
      if (switchsObj(param) == true) {
        // console.log(switchsObj(switchs),"switchsObj(param)")
        const res = await this.$axios.post(
          "/httpServe/monitorExEventRule/save",
          param,
          true
        );
        if (res.data == -1) {
          this.$message({
            message: this.$t('AppManage.AppSecurityEvent.Savefail'),
            type: "error",
            offset: 100,
          });
          return;
        } else if (res.errorCode == "788740") {
          this.$message({
            message: this.$t('AppManage.AppSecurityEvent.Savefailcontent'),
            type: "error",
            offset: 100,
          });
          return;
        } else {
          // this.Successdialog = true
          // this.seccess = '保存成功'
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: "success",
            offset: 100,
          });
          this.form = {
            name: "", //名称
            memo: "", //描述
            level: "3", //级别，1-低，2-中，3-高
            content: {
              //事件内容
              env: {
                //环境状态
                mode: "0", //0-满足任一勾选条件，1-满足全部勾选条件，以下同
                root: {
                  //ROOT/越狱
                  switch: "0", //1-勾选，0-未勾选，以下同
                },
                usb: {
                  //USB调试
                  switch: "0",
                },
                location: {
                  //允许模拟位置
                  switch: "0",
                },
                rom: {
                  //定制ROM
                  switch: "0",
                },
                vm: {
                  //模拟器
                  switch: "0",
                },
              },
              threat: {
                //威胁维度
                mode: "0",
                count: {
                  //威胁次数
                  switch: "",
                  type: "S_CHECK_INJECT_STATUS", //威胁类型, 参考国网总部
                  count: "10", //次数
                },
                category: {
                  //威胁种类
                  switch: "",
                  count: "1", //种类数
                },
                type: {
                  //包含威胁
                  switch: "",
                  type: [], //选择威胁种类
                },
              },
              action: "0", //4-告警提示，8-清除数据， 2-退出应用
              time: "1", //封禁数量
              unit: "hour", //封禁单位，hour-小时，count-次数
            },
          };
          this.$emit('addEventConfig',0)
        }
      } else {
        this.$message({
          message: this.$t('AppManage.AppSecurityEvent.Pleaseconfigure'),
          type: "error",
          offset: 100,
        });
      }
    },
  },
}

</script>
<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}

.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}

.zrgj {
  width: 100%;
  height: 100%;
}

// 处理按钮
.btn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 1px;
}

.save {
  margin: 14px 0 102px 180px;
}

.el-form {
  margin-top: 25px;
}

.el-icon-edit,
#el-icon-close {
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  margin-right: 5px;
  color: #4787de;
  font-weight: bold;
}

// .el-input {
//   width: 50%;
// }

#text {
  resize: none;
}

span>>>.el-input>input {
  height: 24px;
}

.el-form-item__content {
  width: 78%;
  display: flex;
  margin: 0 !important;
}

// #success{
//   // height: 100px;
// }
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;

  // background:rgba(255, 255, 255,.1)
  li {
    list-style: none;
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;

    div {
      display: inline-block;
    }
  }

  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}

.el-button+.el-button {
  margin-left: 0px;
}

.el-checkbox {
  margin-right: 10px;
}

.el-radio,
.el-radio__input {
  margin-left: 10px;
}

.el-checkbox,
.el-checkbox__input {
  margin-left: 3px;
}

.el-textarea__inner {
  resize: none;
}

.el-select .el-input {
  width: 120px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.el-checkbox:last-of-type {
  margin-left: 12px;
}

.el-form {
  width: 848px;
}

// .el-form-item {
//   margin-bottom: 5px;
// }
.el-form[data-v-42c2d864] {
  margin-top: 30px;
}

.el-form-item__content {
  width: 662px;
  display: flex;
  margin: 0 !important;
}

.el-dialog .el-dialog__body {
  padding: 5px 12px 24px 20px !important;
  //height: 100px;
  overflow: auto;
  margin-right: 12px;
}

// .center {
//   width: 100%;
// margin-left: 10px;
// margin-right: 10px;
// margin-top: 10px;
/* display: flex; */
/* justify-content: space-between; */
//   justify-content: flex-start;
// }
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  height: 14px;
  line-height: 20px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.el-form-item__content {
  line-height: 36px;
  position: relative;
  font-size: 14px;
}

.save[data-v-42c2d864] {
  margin: 14px 0 102px 180px !important;
  margin-bottom: 110px;
}
</style>
<style lang="scss">
.save[data-v-42c2d864] {
  margin: 14px 0 102px 180px !important;
  margin-bottom: 110px;
}
</style>